var THF = THF || {};
THF.Interface = THF.Interface || {};

(function ($, viewport) {
    'use strict';

    THF.Interface.QuotePanel = function (options) {

        var defaults = {
            requireCookie: true,
            animation: {
                speed: 350,
                easing: 'easeOutQuart'
            },

            /**
			 * When to trigger the transition for this element
			 *
			 * The transition will happen when the top or bottom
			 * of the triggerTransitionElem is reached on the page.
			 */
            transition: {
                desktop: {
                    loadHidden: false,
                    animate: true,
                    when: 'bottom'
                },
                mobile: {
                    loadHidden: false,
                    animate: false,
                    when: 'top'
                }
            },

            selectors: {
                parentElem: '#quote-panel.quote-panel-global',
                // Triggers the transition when reaching the top or bottom of this element.
                triggerTransitionElem: '#quote-panel.quote-panel-global',
                innerElem: '.inner-wrap',
                accordionTrigger: '.btn-accordion-trigger',
                msgBlock: '.message-block',
                closeBtn_mobile: '.btn-close',
                closeBtn_desktop: '.btn-close',
                btnJoinNow: '.sg-btn-join',

                //Content selectors
                displayHospitalCover: '.display-hospital-cover',
                displayHospitalTierName: '.display-hospital-tier-name',

                displayExtrasCover: '.display-extras-cover',
                displayPaymentFrequency: '.display-payment-frequency',
                displayPrice: '.display-price',

                //Disclaimer
                displayRebatePercentage: '.display-rebate-percentage',
                displayLHCPercentage: '.display-LHC-percentage',
                displayExcess: '.display-excess',
                displayCoverType: '.display-cover-type',
                displayState: '.display-state',
                displayApril: '.display-april',

                //Lead capture parent
                leadCapture: '.lead-capture',
                leadCaptureInnerElem: '.lead-capture .inner-wrap',
            }
        };

        options = $.extend(true, {}, defaults, options);


        /**********************************
		// Declare variables
		**********************************/
        var parentElem = $(options.selectors.parentElem);
        var triggerTransitionElem = $(options.selectors.triggerTransitionElem);
        var innerElem = $parentElem(options.selectors.innerElem);
        var accordionTrigger = $parentElem(options.selectors.accordionTrigger);
        var msgBlock = $parentElem(options.selectors.msgBlock);
        var closeBtn_mobile = $parentElem(options.selectors.closeBtn_mobile);
        var closeBtn_desktop = $parentElem(options.selectors.closeBtn_desktop);
        var btnJoinNow = $parentElem(options.selectors.btnJoinNow);

        //Content display elements
        var displayHospitalCover = $(options.selectors.displayHospitalCover);
        var displayExtrasCover = $(options.selectors.displayExtrasCover);
        var displayPaymentFrequency = $(options.selectors.displayPaymentFrequency);
        var displayPrice = $(options.selectors.displayPrice);
        var displayHospitalTierName = $(options.selectors.displayHospitalTierName);

        //Disclaimer
        var displayRebatePercentage = $(options.selectors.displayRebatePercentage);
        var displayLHCPercentage = $(options.selectors.displayLHCPercentage);
        var displayExcess = $(options.selectors.displayExcess);
        var displayCoverType = $(options.selectors.displayCoverType);
        var displayState = $(options.selectors.displayState);
        var displayApril = $(options.selectors.displayApril);

        //Lead Capture tools
        var leadCapture = $(options.selectors.leadCapture);
        var leadCaptureInnerElem = $(options.selectors.leadCaptureInnerElem);
        

        /**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
        function $parentElem(selector) {
            return $(options.selectors.parentElem + ' ' + selector);
        }

        /**********************************
		// Interface events
		**********************************/
        function showQuotePanel() {
            innerElem.css({
                top: 0 - (parentElem.outerHeight())
            }).velocity({
                properties: {
                    translateY: parentElem.outerHeight()
                },

                options: {
                    duration: options.animation.speed,
                    easing: options.animation.easing
                }
            });
        }

        function resetQuotePanel() {
            innerElem.velocity({
                properties: {
                    translateY: 0
                },


                options: {
                    duration: 10,
                    easing: options.animation.easing,
                    complete: function (elements) {
                        $(elements).css({
                            top: 0
                        });
                    }
                }
            });
        }

        function showLeadCapture(){
            
            var translateVal = 90;

            if (viewport.is('xs') || viewport.is('sm')) {
                translateVal = 68;
            }
            
            leadCaptureInnerElem.css({
                top: 0 - (leadCapture.outerHeight())
            }).velocity({
                properties: {
                    translateY: translateVal
                },

                options: {
                    duration: options.animation.speed,
                    easing: options.animation.easing
                }
            });
        }

        function hideLeadCapture(){
            leadCaptureInnerElem.velocity({
                properties: {
                    translateY: 0
                },


                options: {
                    duration: 10,
                    easing: options.animation.easing,
                    complete: function (elements) {
                        $(elements).css({
                            top: 0
                        });
                    }
                }
            });
        }

        function showMessageBlock() {
            msgBlock.velocity("slideDown", { duration: options.animation.speed });
        }

        function hideMessageBlock() {
            msgBlock.velocity("slideUp", { duration: options.animation.speed });
        }

        function closeQuotePane_desktop() {
            innerElem.velocity({
                properties: {
                    translateY: 0
                },

                options: {
                    duration: options.animation.speed,
                    easing: options.animation.easing,

                    complete: function () {
                        parentElem.css('display', 'none');
                    }
                }
            });
        }

        function closeQuotePane_mobile() {
            innerElem.velocity({
                properties: {
                    opacity: 0,
                    translateY: -20
                },

                options: {
                    duration: options.animation.speed,
                    easing: options.animation.easing,

                    complete: function () {
                        parentElem.css('display', 'none');
                    }
                }
            });
        }


        function renderPanelContent(cookieData) {

            cookieData.extended = cookieData.extended || false;
            cookieData.displayCoverType = cookieData.displayCoverType || '';
            cookieData.displayState = cookieData.displayState || '';

            displayHospitalCover.html(cookieData.displayHospitalCover);
            displayHospitalTierName.html(cookieData.displayHospitalTierName);
            displayExtrasCover.html(cookieData.displayExtrasCover);
            displayPaymentFrequency.html(cookieData.displayPaymentFrequency);
            displayPrice.html(cookieData.displayPrice);
            
            if(cookieData.displayHospitalTierName === '') {
                displayHospitalTierName.hide();
            }
            else {
                displayHospitalTierName.show();
            }

            //disclaimer
            displayRebatePercentage.html(cookieData.displayRebatePercentage);
            displayLHCPercentage.html(cookieData.displayLHCPercentage);
            displayExcess.html(cookieData.displayExcess);
            var coverTypeText = cookieData.displayCoverType+((cookieData.extended) ? ' Extended' : '' );
            displayCoverType.html(coverTypeText);
            displayState.html(cookieData.displayState);

            if( cookieData.asAtDateApril ) {
                displayApril.show();
            }
            else {
                displayApril.hide();
            }
        }


        function displayPanel() {
            parentElem.css('display', 'block');
        }

        /**********************************
		// Initializers
		**********************************/
        function initAffix() {
            var previousOffset = 0;

            parentElem.affix({
                offset: {
                    top: function () {
                        if (!parentElem.hasClass('affix')) {
                            var offset = triggerTransitionElem.offset();

                            var triggerHeight = triggerTransitionElem.outerHeight();
                            previousOffset = offset.top;

                            if (viewport.is('xs')) {
                                if (options.transition.mobile.when == 'bottom') {
                                    previousOffset += triggerHeight;
                                }
                            }
                            else {
                                if (options.transition.desktop.when == 'bottom') {
                                    previousOffset += triggerHeight;
                                }
                            }
                        }
                        return previousOffset;
                    }
                }
            });

            leadCapture.affix({
                offset: {
                    top: function () {
                        if (!parentElem.hasClass('affix')) {
                            var offset = triggerTransitionElem.offset();

                            var triggerHeight = triggerTransitionElem.outerHeight();
                            previousOffset = offset.top;

                            if (viewport.is('xs')) {
                                if (options.transition.mobile.when == 'bottom') {
                                    previousOffset += triggerHeight;
                                }
                            }
                            else {
                                if (options.transition.desktop.when == 'bottom') {
                                    previousOffset += triggerHeight;
                                }
                            }
                        }
                        return previousOffset;
                    }
                }
            });


        };

        function bindEvents() {
            parentElem.unbind('affix.bs.affix').on('affix.bs.affix', function () {

                var desktopAnimate = (!viewport.is('xs') && options.transition.desktop.animate);
                var mobileAnimate = (viewport.is('xs') && options.transition.mobile.animate);
                var desktopLoadHidden = (!viewport.is('xs') && options.transition.desktop.loadHidden);
                var mobileLoadHidden = (viewport.is('xs') && options.transition.mobile.loadHidden);

                if (mobileLoadHidden || desktopLoadHidden) {
                    $(this).css({ top: 0 });
                }

                if (mobileAnimate || desktopAnimate) {
                    showQuotePanel();
                    showLeadCapture();
                }

            });

            parentElem.unbind('affix-top.bs.affix').on('affix-top.bs.affix', function () {

                var desktopAnimate = (!viewport.is('xs') && options.transition.desktop.animate);
                var mobileAnimate = (viewport.is('xs') && options.transition.mobile.animate);
                var desktopLoadHidden = (!viewport.is('xs') && options.transition.desktop.loadHidden);
                var mobileLoadHidden = (viewport.is('xs') && options.transition.mobile.loadHidden);

                if (mobileLoadHidden || desktopLoadHidden) {
                    $(this).css({ top: -1000 });
                }

                if (mobileAnimate || desktopAnimate) {
                    resetQuotePanel();
                    hideLeadCapture();
                }
            });

            accordionTrigger.unbind('click').click(function (e) {

                if ($(this).hasClass('active')) {

                    $(this).removeClass('active');
                    hideMessageBlock();

                } else {

                    $(this).addClass('active');
                    showMessageBlock();
                }

                e.preventDefault();
            });

            closeBtn_desktop.unbind('click').click(function (e) {
                closeQuotePane_desktop();
                e.preventDefault();
            });

            closeBtn_mobile.unbind('click').click(function (e) {
                closeQuotePane_mobile();
                e.preventDefault();
            });

            $parentElem('.btn-edit').unbind('click').click(function () {
                DSTAPI.track('event', {
                    category: 'Live Quote Tool � Sticky Module Interaction',
                    action: 'Click',
                    label: 'Edit'
                });
            });

            $parentElem('.btn-change').unbind('click').click(function () {
                DSTAPI.track('event', {
                    category: 'Live Quote Tool � Sticky Module Interaction',
                    action: 'Click',
                    label: 'Change your cover or compare'
                });
            });

        };

        function bindJoinButton(cookieData) {
            btnJoinNow.unbind('click').click(function() {
                var hrc = THF.Utility.HealthRateCode.decode(cookieData.healthRateCode);
                if (hrc !== false) {
                    DSTAPI.track('event', {
                        category: 'Live Quote Tool - Sticky Module Interaction',
                        action: 'Click',
                        label: 'Join'
                    });

                    THF.Utility.Location.navigateToJoinForm(
                        hrc.state,
                        hrc.membershipType,
                        hrc.hospitalCover,
                        hrc.ancillaryCover
                    );
                }

            });

        }

        /**********************************
		// Simple constructor
		**********************************/
        function __construct() {

            // Init

            if (parentElem.length) {

                var cookieData = Cookies.getJSON('thf-live-quote-tool');

                if( !options.requireCookie ) {
                    initAffix();
                    bindEvents();
                    displayPanel();
                }
                else if (options.requireCookie && typeof cookieData == 'object') {

                    var path = window.location.pathname + '/live-quote-sticky';
                    DSTAPI.track('page', {
                        url: path.replace('//', '/')
                    });

                    renderPanelContent(cookieData);
                    initAffix();
                    bindEvents();
                    bindJoinButton(cookieData);
                    displayPanel();
                }
            }
        }

        __construct();

        //
        // Make some events accessible from global scope
        //
        return {
        };
    };


})(jQuery, ResponsiveBootstrapToolkit);